import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@material-ui/core';
import { compact, keyBy, truncate } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Check as IconCheck, Edit as IconEdit } from 'react-feather';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import {
  getAppliedLabel,
  MultiSelector,
  MultiSelectorChip,
  MultiSelectorValueOption
} from '../../../../../components/MultiSelector';
import { SearchInput } from '../../../../../components/SearchInput';
import {
  TableToolbar,
  TableToolbarSection
} from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { ColumnSelector } from '../../../../../components/Table/ColumnSelector';
import {
  BillingStatus,
  getBillingStatus,
  getPlanFrequency,
  getPlanType,
  hasSubscriptionExpired,
  hasTrialExpired,
  isInTrial,
  isInTrialGracePeriod,
  isInTrialIncludingGracePeriod,
  isPayingCustomer,
  isUsingForFree
} from '../../../../../domainTypes/billing';
import { Doc } from '../../../../../domainTypes/document';
import { ISpace } from '../../../../../domainTypes/space';
import {
  mergeSessionData,
  UserEngagementSessions
} from '../../../../../domainTypes/userEngagement';
import { css } from '../../../../../emotion';
import { Section } from '../../../../../layout/Section';
import {
  queryParamToList,
  setToQueryParam,
  useQueryParam,
  useStringQueryParam
} from '../../../../../routes';
import {
  combineLoadingValues,
  useMappedLoadingValue
} from '../../../../../services/db';
import { getActiveDomainUrls } from '../../../../../services/space';
import {
  formatDate,
  formatDatePrecise,
  toMoment
} from '../../../../../services/time';
import { IStatsBarItem, StatsBar } from '../../../../components/StatsBar';
import {
  getUserIdsInSpace,
  matchSpace,
  updateSpace,
  useSpaces
} from '../../../../services/space';
import { useAllUserEngagementData } from '../../../../services/userEngagement';

type ColumnName =
  | 'id'
  | 'domain'
  | 'plan'
  | 'trial'
  | 'nextBilling'
  | 'active'
  | 'sessions'
  | 'lastSeen'
  | 'createdAt'
  | 'tz'
  | 'currency';

const ClickInterceptor: React.FC = ({ children }) => {
  return <div onClick={(ev) => ev.stopPropagation()}>{children}</div>;
};

const TimezoneUpdateDialog: React.FC<{ d: Doc<ISpace> }> = ({ d }) => {
  const tz = d.data.config.tz || '';
  const [nextTz, setNextTz] = useState(tz);
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const update = () =>
    updateSpace(d.id, {
      config: {
        ...d.data.config,
        tz: nextTz
      }
    }).then(close);
  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        <IconEdit size={16} />
      </IconButton>

      <Dialog open={open} onClose={close}>
        <DialogTitle>Edit Timezone</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth={true}
            value={nextTz}
            onChange={(ev) => setNextTz(ev.target.value)}
          />
          <ButtonWithPromise
            onClick={update}
            pending="Updating..."
            variant="contained"
          >
            Update
          </ButtonWithPromise>
        </DialogContent>
      </Dialog>
    </>
  );
};

interface ISpaceWithEngagmentData extends ISpace {
  engagement: {
    sessions: UserEngagementSessions; // eventually extend on a per user basis
  };
}

type D = Doc<ISpaceWithEngagmentData>;
type Column = IColumn<D, ColumnName>;

const COLUMNS: Column[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => <div>{truncate(d.id, { length: 12 })}</div>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'domain',
    head: () => 'Domains',
    cell: (d) => getActiveDomainUrls(d.data).join(', '),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 3
  },
  {
    key: 'plan',
    head: () => 'Plan',
    cell: ({
      data: {
        billing: { activePlan: a }
      }
    }) => {
      const type = getPlanType(a);
      return type ? `${type} (${getPlanFrequency(a)})` : '';
    },
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'trial',
    head: () => 'Trial',
    cell: (d) =>
      d.data.billing.trialUntil && formatDatePrecise(d.data.billing.trialUntil),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'nextBilling',
    head: () => 'Next billing',
    cell: (d) =>
      d.data.billing.subscribedUntil &&
      formatDatePrecise(d.data.billing.subscribedUntil),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'active',
    head: () => 'Active',
    cell: (d) => d.data.active && <IconCheck />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'tz',
    head: () => 'TZ',
    cell: (d) => (
      <ClickInterceptor>
        {d.data.config.tz || ''}
        <TimezoneUpdateDialog d={d} />
      </ClickInterceptor>
    ),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'currency',
    head: () => 'Currency',
    cell: (d) => <ClickInterceptor>{d.data.config.currency}</ClickInterceptor>,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'sessions',
    head: () => 'Sessions',
    cell: (d) => d.data.engagement.sessions.count,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 60,
    flexGrow: 1
  },
  {
    key: 'lastSeen',
    head: () => 'Last seen',
    cell: (d) =>
      d.data.engagement.sessions.lastSeen
        ? formatDatePrecise(toMoment(d.data.engagement.sessions.lastSeen))
        : '',
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 120,
    flexGrow: 1
  },
  {
    key: 'createdAt',
    head: () => 'Created at',
    cell: (d) => formatDate(toMoment(d.data.createdAt), 'YYYY/MM/DD'),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 90,
    flexGrow: 1
  }
];

const SORTERS: ItemSorters<D> = {
  id: {
    key: 'id',
    items: { sort: (d) => d.id, dir: 'asc' }
  },
  domain: {
    key: 'domain',
    items: { sort: (d) => getActiveDomainUrls(d.data)[0], dir: 'asc' }
  },
  plan: {
    key: 'plan',
    items: { sort: (d) => d.data.billing.activePlan || 'ZZZZZ', dir: 'asc' }
  },
  trial: {
    key: 'trial',
    items: { sort: (d) => d.data.billing.trialUntil || Infinity, dir: 'asc' }
  },
  nextBilling: {
    key: 'nextBilling',
    items: {
      sort: (d) => d.data.billing.subscribedUntil || Infinity,
      dir: 'asc'
    }
  },
  active: {
    key: 'active',
    items: { sort: (d) => d.data.active, dir: 'asc' }
  },
  tz: {
    key: 'tz',
    items: { sort: (d) => d.data.config.tz || 'ZZZZZ', dir: 'asc' }
  },
  currency: {
    key: 'currency',
    items: { sort: (d) => d.data.config.currency || 'ZZZZZ', dir: 'asc' }
  },
  sessions: {
    key: 'sessions',
    items: { sort: (d) => d.data.engagement.sessions.count, dir: 'desc' }
  },
  lastSeen: {
    key: 'lastSeen',
    items: {
      sort: (d) =>
        d.data.engagement.sessions.lastSeen
          ? d.data.engagement.sessions.lastSeen.toMillis()
          : 0,
      dir: 'desc'
    }
  },
  createdAt: {
    key: 'createdAt',
    items: { sort: (d) => d.data.createdAt.toMillis(), dir: 'desc' }
  }
};

const filterByBillingStatus = (
  spaces: D[],
  billingStatusFilter: Set<BillingStatus>
) => {
  if (!billingStatusFilter.size) {
    return [];
  }
  const n = Date.now();
  return spaces.filter((s) => {
    const status = getBillingStatus(s.data, n);
    return billingStatusFilter.has(status);
  });
};

const filterBySearchTerm = (spaces: D[], term: string) => {
  if (!term) {
    return spaces;
  }
  return spaces.filter((s) => matchSpace(s.data, term));
};

const filterSpaces = (
  docs: D[],
  term: string,
  billingStatusFilter: Set<BillingStatus>
) => {
  let ss = docs;
  ss = filterByBillingStatus(ss, billingStatusFilter);
  ss = filterBySearchTerm(ss, term);
  return ss;
};

const getRowColor = (space: Doc<ISpace> | null, now: number) => {
  if (space) {
    const { billing } = space.data;
    if (hasTrialExpired(billing, now)) {
      return isInTrialGracePeriod(billing, now)
        ? '#fff6ce' // a faint yellow
        : '#ffd9d9'; // a faint red
    }
    if (isInTrial(billing, now)) {
      return '#c3ebff'; // a faint blue
    }
    if (isUsingForFree(billing)) {
      return '#eeffe2'; // a faint green
    }
  }
  return 'none';
};

const getRowClassName = (d: D) => {
  const background = getRowColor(d, Date.now());
  return css(() => ({
    background: d.data.active
      ? background
      : `repeating-linear-gradient(90deg, ${background}, ${background} 5px, white 6px, white 8px)`
  }));
};

const BILLING_STATUS_FILTERS: MultiSelectorValueOption<BillingStatus>[] = [
  {
    label: 'Paying',
    value: 'PAYING'
  },
  {
    label: 'Trial',
    value: 'TRIAL'
  },
  {
    label: 'Trial Grace Period',
    value: 'TRIAL_GRACE'
  },
  {
    label: 'Trial expired',
    value: 'TRIAL_EXPIRED'
  },
  {
    label: 'Subscription Grace Period',
    value: 'SUBSCRIPTION_GRACE'
  },
  {
    label: 'Subscription Expired',
    value: 'SUBSCRIPTION_EXPIRED'
  },
  {
    label: 'Free',
    value: 'FREE'
  }
];
const BILLING_STATUSES = BILLING_STATUS_FILTERS.map((d) => d.value);

type SpaceCounter = {
  count: number;
  spaceIds: string[];
};

const getBillingStats = (spaces: ISpace[], now: number) => {
  return spaces.reduce<{
    paying: SpaceCounter;
    trialing: SpaceCounter;
    notConverted: SpaceCounter;
    churned: SpaceCounter;
    free: SpaceCounter;
  }>(
    (m, s) => {
      const { billing } = s;
      if (isUsingForFree(billing)) {
        m.free.count++;
        m.free.spaceIds.push(s.id);
      } else if (isInTrialIncludingGracePeriod(billing, now)) {
        m.trialing.count++;
        m.trialing.spaceIds.push(s.id);
      } else if (isPayingCustomer(billing, now)) {
        m.paying.count++;
        m.paying.spaceIds.push(s.id);
      } else if (hasSubscriptionExpired(billing, now)) {
        m.churned.count++;
        m.churned.spaceIds.push(s.id);
      } else {
        m.notConverted.count++;
        m.notConverted.spaceIds.push(s.id);
      }
      return m;
    },
    {
      paying: { count: 0, spaceIds: [] },
      trialing: { count: 0, spaceIds: [] },
      notConverted: { count: 0, spaceIds: [] },
      churned: { count: 0, spaceIds: [] },
      free: { count: 0, spaceIds: [] }
    }
  );
};

const toStatsBarData = (
  docs: Doc<ISpace>[] | void,
  setFilters: (filters: BillingStatus[]) => void
): IStatsBarItem[] | void => {
  if (!docs) {
    return;
  }

  const stats = getBillingStats(
    docs.map((s) => s.data),
    Date.now()
  );

  return [
    {
      label: 'Paying',
      value: stats.paying.count,
      onClick: () => setFilters(['PAYING'])
    },
    {
      label: 'In Trial',
      value: stats.trialing.count,
      onClick: () => setFilters(['TRIAL'])
    },
    {
      label: 'Trial Expired',
      value: stats.notConverted.count,
      onClick: () => setFilters(['TRIAL_GRACE', 'TRIAL_EXPIRED'])
    },
    {
      label: 'Churned',
      value: stats.churned.count,
      onClick: () => setFilters(['SUBSCRIPTION_GRACE', 'SUBSCRIPTION_EXPIRED'])
    },
    {
      label: 'Free',
      value: stats.free.count,
      onClick: () => setFilters(['FREE'])
    }
  ];
};

const Stats = ({
  docs,
  loading,
  error,
  setBillingStatuses
}: {
  docs: void | Doc<ISpace>[];
  loading: boolean;
  error: any;
  setBillingStatuses: (next: Set<BillingStatus>) => void;
}) => {
  return (
    <StatsBar
      data={toStatsBarData(docs, (filters) =>
        setBillingStatuses(new Set(filters))
      )}
      loading={loading}
      error={error}
    />
  );
};
const DEFAULT_COLUMNS: ColumnName[] = [
  'id',
  'domain',
  'plan',
  'trial',
  'tz',
  'currency',
  'sessions',
  'createdAt'
];

const ROW_TO_KEY = (d: D) => d.id;
const ROW_TO_HREF = (d: D) => `/spaces/${d.id}`;

const BillingStatusSelector = ({
  value,
  onChange
}: {
  value: Set<BillingStatus>;
  onChange: (nextvalue: Set<BillingStatus>) => void;
}) => {
  const isApplied = BILLING_STATUSES.length !== value.size;
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      legend="Filter by status"
      options={BILLING_STATUS_FILTERS}
      allOption={<strong>All</strong>}
      allowFocusing
    >
      <MultiSelectorChip
        isApplied={isApplied}
        onDelete={() => onChange(new Set(BILLING_STATUSES))}
        label="Filter by status"
        appliedLabel={getAppliedLabel(
          'status',
          isApplied
            ? BILLING_STATUS_FILTERS.filter((s) => value.has(s.value)).map(
                (s) => s.label as string
              )
            : []
        )}
      />
    </MultiSelector>
  );
};

const useSpacesWithEngagementData = () =>
  useMappedLoadingValue(
    combineLoadingValues(useSpaces(), useAllUserEngagementData()),
    ([spaces, engagements]) => {
      const byUserId = keyBy(engagements, (e) => e.id);
      return spaces.map<Doc<ISpaceWithEngagmentData>>((s) => {
        const userIds = getUserIdsInSpace(s.data);
        const sessions = mergeSessionData(
          compact(userIds.map((uId) => byUserId[uId])).map(
            (d) => d.data.sessions
          )
        );
        return {
          ...s,
          data: {
            ...s.data,
            engagement: { sessions }
          }
        };
      });
    }
  );

export const PageSpacesList = () => {
  const [docs, loading, error] = useSpacesWithEngagementData();

  const [search, setSearch] = useStringQueryParam('q');
  const [columnNames, setColumnNames] = useColumnsQueryParam(
    'columns',
    DEFAULT_COLUMNS
  );
  const [billingStatuses, setBillingStatuses] = useQueryParam(
    'billingStatus',
    (p) => new Set(p ? queryParamToList<BillingStatus>(p) : BILLING_STATUSES),
    (ss) =>
      ss.size === BILLING_STATUSES.length ? undefined : setToQueryParam(ss)
  );

  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const rows = useMemo(
    () => (docs ? filterSpaces(docs, search, billingStatuses) : docs),
    [docs, search, billingStatuses]
  );

  console.log(rows);

  return (
    <>
      <Section>
        <Stats
          docs={docs}
          loading={loading}
          error={error}
          setBillingStatuses={setBillingStatuses}
        />
      </Section>
      <Section>
        <TableToolbar padding="dense" style={{ padding: '12px 0' }}>
          <SearchInput
            value={search}
            onChange={setSearch}
            placeholder="Search by Space ID or website URL"
            autoFocus={true}
            width={300}
          />
          <TableToolbarSection>
            <BillingStatusSelector
              value={billingStatuses}
              onChange={setBillingStatuses}
            />
            <ColumnSelector
              value={columnNames}
              onChange={setColumnNames}
              columns={COLUMNS}
            />
          </TableToolbarSection>
        </TableToolbar>
        {loading && <Loader />}
        {rows && (
          <RowsRenderer
            variant="contained"
            columns={columns}
            rows={rows}
            rowToKey={ROW_TO_KEY}
            sorter={sorter || SORTERS.domain}
            sortDirection={dir}
            renderHead={true}
            headProps={{ sticky: true, offset: 48 }}
            onHeadClick={(c, d) => setSort([SORTERS[c.key] || SORTERS.url, d])}
            chunkSize={30}
            rootMargin="300px"
            rowHeight={ROW_HEIGHTS.dense}
            otherProps={undefined}
            rowToHref={ROW_TO_HREF}
            rowToClassName={getRowClassName}
          />
        )}
      </Section>
    </>
  );
};
