import { Button, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { truncate } from 'lodash';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy, ExternalLink, Image, RefreshCcw } from 'react-feather';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import { WithShape } from '../../../../../../components/Charts/Util';
import { PartnerLogo } from '../../../../../../components/PartnerLogo';
import { PlatformWithColor } from '../../../../../../components/PlatformWithColor';
import { Truncated } from '../../../../../../components/Truncated';
import { Doc } from '../../../../../../domainTypes/document';
import { styled } from '../../../../../../emotion';
import { withStoppedPropagation } from '../../../../../../helpers';
import { useErrorLogger } from '../../../../../../hooks/useErrorLogger';
import { usePromise } from '../../../../../../hooks/usePromise';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { FlexContainer } from '../../../../../../layout/Flex';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../../../services/partner';
import {
  getProductByIdPg,
  normalizeLinkUrl,
  renderLinkName,
  tryUpdateDestinationUrlForProductById
} from '../../../../../../services/products';

const ProductLink = styled('span')`
  position: relative;
  display: inline-block;
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  color: ${(p) => p.theme.palette.grey[500]};
`;

const Wrapper = styled('span')`
  width: 100%;
  display: block;

  .copy-clipboard {
    display: none;
    position: absolute;
    right: -50px;
    color: ${(p) => p.theme.palette.grey[600]};

    &:hover {
      color: ${(p) => p.theme.palette.grey[800]};
    }
  }

  &:hover .copy-clipboard {
    display: initial;
  }
`;

const LinkText = styled('span')`
  display: inline-block;
`;

export const ProductLinkCell = <
  T extends { url: string; name: string; partner_key: string }
>({
  p
}: {
  p: Doc<T>;
}) => {
  const linkName = renderLinkName({
    name: p.data.name,
    url: p.data.url,
    partnerKey: p.data.partner_key
  });

  return (
    <Tooltip title={linkName || ''} placement="top">
      <Wrapper>
        <Typography
          style={{
            fontSize: '13px',
            fontWeight: 700,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          title="See more details"
        >
          {linkName}
        </Typography>
        <ProductLink>
          <LinkText>{truncate(p.data.url, { length: 60 })}</LinkText>
        </ProductLink>
      </Wrapper>
    </Tooltip>
  );
};

const ProductImageContainer = styled('div')`
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  box-sizing: border-box; /* Include padding in size */
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(p) => p.theme.palette.common.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  color: ${(p) => p.theme.palette.grey[400]}; /* for fallback icon */

  img {
    display: block; /* Prevent inline element margins */
    object-fit: contain; /* Maintain aspect ratio */
    width: 42px;
    height: 42px;
  }
`;

const ProductCatalogWrapper = styled('div')`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 50px 1fr; /* Image column and description column */
  align-items: center;
`;

const DescriptionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Constrain description to fit in the 1fr column */
`;

const TitleTypography = styled(Typography)`
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; /* Required for ellipsis to work */
`;

const ProductLinkForCatalog = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LinkTextForCatalog = styled('span')`
  font-size: 12px;
  color: ${(p) => p.theme.palette.text.secondary};
`;

export const LinkCellWithProductCatalogData = ({
  link_name,
  link_dest_url,
  p_title,
  p_image_url
}: {
  link_name: string;
  link_dest_url: string;
  p_title: string;
  p_image_url: string;
}) => {
  return (
    <Tooltip title={link_name || ''} placement="top">
      <ProductCatalogWrapper>
        <ProductImageContainer>
          {p_image_url ? (
            <img src={p_image_url} alt={p_title} />
          ) : (
            <Image size={24} />
          )}
        </ProductImageContainer>
        <DescriptionWrapper>
          <TitleTypography
            title={link_name}
            style={{
              fontSize: '13px',
              fontWeight: 700
            }}
          >
            {link_name}
          </TitleTypography>
          <ProductLinkForCatalog>
            <LinkTextForCatalog title={link_dest_url}>
              {truncate(link_dest_url, { length: 60 })}
            </LinkTextForCatalog>
          </ProductLinkForCatalog>
        </DescriptionWrapper>
      </ProductCatalogWrapper>
    </Tooltip>
  );
};

export const ProductLinkCellWithPartner = <
  T extends { url: string; name: string; partner_key: string }
>({
  p
}: {
  p: Doc<T>;
}) => {
  const linkName = renderLinkName({
    name: p.data.name,
    url: p.data.url,
    partnerKey: p.data.partner_key
  });

  const partner =
    getKnownPartnerForKey(p.data.partner_key) ||
    constructPartnerForKey(p.data.partner_key);

  return (
    <Tooltip
      title={
        <div>
          <PlatformWithColor partner={partner} />
          {linkName}
        </div>
      }
      placement="top"
    >
      <FlexContainer>
        <WithShape shape="square" color={partner.color} large />
        <Wrapper>
          <Typography
            style={{
              fontSize: '13px',
              fontWeight: 700,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            title="See more details"
          >
            {linkName}
          </Typography>
          <ProductLink>
            <LinkText>{truncate(p.data.url, { length: 60 })}</LinkText>
          </ProductLink>
        </Wrapper>
      </FlexContainer>
    </Tooltip>
  );
};

const ProductSkeleton = styled(Skeleton)`
  width: 30%;
`;

const LinkWrapper = styled('div')`
  word-break: break-all;
  background-color: ${(p) => p.theme.palette.grey[100]};
  padding: 0 ${(p) => p.theme.spacing(1)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin: ${(p) => p.theme.spacing(1)}px 0;
`;

export const ProductLinkTitleWithPartner = <
  T extends {
    url: string;
    destination_url: string;
    name: string;
    partner_key: string;
  }
>({
  p,
  refreshProduct
}: {
  p: Doc<T>;
  refreshProduct?: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const linkName = renderLinkName({
    name: p.data.name,
    url: p.data.url,
    partnerKey: p.data.partner_key
  });

  const partner =
    getKnownPartnerForKey(p.data.partner_key) ||
    constructPartnerForKey(p.data.partner_key);

  const { normalizedUrl } = normalizeLinkUrl(p.data.url, p.data.partner_key);

  return (
    <div>
      <Typography
        variant="h6"
        component="p"
        style={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: 'max-content 1fr',
          gap: '8px',
          fontWeight: 'bold',
          maxWidth: '100%'
        }}
      >
        <PartnerLogo partner={partner} />
        <Truncated title={linkName || ''}>{linkName}</Truncated>
      </Typography>
      <LinkWrapper>
        {p.data.url === p.data.destination_url ? (
          <Typography
            variant="caption"
            component="span"
            color="textSecondary"
            gutterBottom
          >
            {p.data.url}
          </Typography>
        ) : (
          <>
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              gutterBottom
            >
              <strong>Displayed URL:</strong> {p.data.url}
            </Typography>
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              gutterBottom
            >
              <strong>Destination URL:</strong> {p.data.destination_url}
            </Typography>
          </>
        )}
      </LinkWrapper>
      <FlexContainer fullWidth>
        <CopyToClipboard text={normalizedUrl}>
          <Button
            className="copy-clipboard"
            title="Click to copy link"
            startIcon={<Copy size={12} />}
            onClick={withStoppedPropagation(() => {
              return enqueueSnackbar('Link copied!', { variant: 'info' });
            })}
          >
            Copy link
          </Button>
        </CopyToClipboard>
        <Button
          startIcon={<ExternalLink size={12} />}
          href={normalizedUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to link
        </Button>
        <ButtonWithPromise
          startIcon={<RefreshCcw size={12} />}
          pending={'Refreshing...'}
          onClick={() =>
            tryUpdateDestinationUrlForProductById(p.id).then((wasUpdated) => {
              if (wasUpdated) {
                enqueueSnackbar('Destination URL updated!', {
                  variant: 'success'
                });
                refreshProduct?.();
              } else {
                enqueueSnackbar('Destination URL is already up-to-date.', {
                  variant: 'info'
                });
              }
            })
          }
        >
          Refresh destination URL
        </ButtonWithPromise>
      </FlexContainer>
    </div>
  );
};

export const ProductLinkTitleWithPartnerLazyForDetailsModal = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const [product, loading, error, refreshProduct] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);
  useErrorLogger(error); // no real need to show this, but otherwise we can't destructure without a linter error :facepalm:

  if (loading) {
    return <ProductSkeleton />;
  }
  if (!product) {
    return <div>{productId}</div>;
  }
  return (
    <ProductLinkTitleWithPartner
      p={{ id: productId, collection: 'NOT_DEFINED', data: product }}
      refreshProduct={refreshProduct}
    />
  );
};

export const ProductLinkCellLazy = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const [product, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);

  if (loading) {
    return <ProductSkeleton />;
  }
  if (!product) {
    return <div>{productId}</div>;
  }
  return (
    <ProductLinkCell
      p={{ id: productId, collection: 'NOT_DEFINED', data: product }}
    />
  );
};

export const ProductLinkCellWithPartnerLazy = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const [product, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);

  if (loading) {
    return <ProductSkeleton />;
  }
  if (!product) {
    return <div>{productId}</div>;
  }
  return (
    <ProductLinkCellWithPartner
      p={{ id: productId, collection: 'NOT_DEFINED', data: product }}
    />
  );
};

export const ProductLinkCellLazyMinimal = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const [p, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);

  if (loading) {
    return <ProductSkeleton />;
  }
  if (!p) {
    return <div>{productId}</div>;
  }

  const { normalizedUrl } = normalizeLinkUrl(p.url, p.partner_key);

  const linkName = renderLinkName({
    name: p.name,
    url: p.url,
    partnerKey: p.partner_key
  });
  return (
    <a href={normalizedUrl} target="_blank" rel="noopener noreferrer">
      <Truncated
        title={`Open "${p.url}" in a new tab`}
        alwaysShowTooltip={linkName !== p.url}
      >
        {linkName}
      </Truncated>
    </a>
  );
};
