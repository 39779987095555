import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import { isEqual, sortBy, sum, uniq } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { CheckboxSimple } from '../../../../../components/CheckboxSimple';
import { Chip } from '../../../../../components/Chip';
import { DeleteButton } from '../../../../../components/DeletionConfirmation';
import { DialogActionsWithSlots } from '../../../../../components/DialogActionsWithSlots';
import { DialogCloseButton } from '../../../../../components/DialogCloseButton';
import { DialogTitleWithTypography } from '../../../../../components/DialogTitleWithTypography';
import {
  IDENTITY_SORTER,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../../components/GroupableList';
import { LinkExternal } from '../../../../../components/LinkExternal';
import { Loader } from '../../../../../components/Loader';
import { Monospace } from '../../../../../components/Monospace';
import {
  SearchInput,
  toSearchRegexp
} from '../../../../../components/SearchInput';
import { SelectionBox } from '../../../../../components/SelectionBox';
import { IColumn } from '../../../../../components/Table/Column';
import { Doc, generateToDocFn } from '../../../../../domainTypes/document';
import {
  ProductCatalogRetrievalProcess,
  ProductCatalogRetrievalProcessSettings,
  ProductFeedDownloadSource,
  ProductFeedLoadSource,
  PRODUCT_CATALOG_RETRIEVAL_PROCESS_SETTINGS_ID,
  RetrievalProcessAbortArgs,
  RetrievalProcessLoadArgs,
  RetrievalProcessStartArgs,
  RetrievalProcessTransformArgs,
  SourceWithStatus
} from '../../../../../domainTypes/productCatalog';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { useModel } from '../../../../../hooks/useModel';
import { useSet } from '../../../../../hooks/useSet';
import { CanvasBar } from '../../../../../layout/Canvas';
import { Centered } from '../../../../../layout/Centered';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { useStringQueryParam } from '../../../../../routes';
import {
  removeDoc,
  setDoc,
  store,
  updateDoc
} from '../../../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../../../services/firecache/documentListener';
import { toLogLink } from '../../../../../services/logging';
import { fromMoment } from '../../../../../services/time';
import { DataGrid, DATA_GRID_SPACER } from '../../../../components/DataGrid';
import { Json } from '../../../../components/Json';
import { toFirestoreConsoleFromDoc } from '../../../../services/firebase';
import { publishInstruction } from '../../../../services/pubsub';
import { getCloudStorageLink } from '../../../../services/storage';

const getLogLinkForProcess = (d: Doc<ProductCatalogRetrievalProcess>) => {
  return toLogLink(
    {
      kind: 'product-catalog-retrieval-logger',
      processId: d.id
    },
    {},
    {
      aroundTime: fromMoment(moment(d.data.startedAt).add(6, 'days')),
      duration: 'P7D'
    }
  );
};

const toRetrievalProcessDoc = generateToDocFn<ProductCatalogRetrievalProcess>(
  (d) => {
    if (!d.downloadSources) {
      const singleSource: SourceWithStatus<ProductFeedDownloadSource> = (d as any)
        .downloadSource;
      d.downloadSources = {
        [singleSource.id]: singleSource
      };
    }
    return d;
  }
);
const collection = () => store().collection('productCatalogRetrievalProcessV1');
const processStore = createCollectionListenerStore(
  () =>
    new CollectionListener(
      collection().orderBy('startedAt', 'desc').limit(1000),
      toRetrievalProcessDoc
    )
);
const processByIdStore = createDocumentListenerGetter(
  (processId) => collection().doc(processId),
  toRetrievalProcessDoc
);

const useProcesses = () => useCollectionListener(processStore(''));
const useProcess = (processId: string) =>
  useDocumentListener(processByIdStore(processId));

const toRetrievalProcessSettingsDoc = generateToDocFn<
  ProductCatalogRetrievalProcessSettings
>((d) => {
  d.discovery = d.discovery || {
    autoRun: false,
    execute: false
  };
  return d;
});

const settingsStore = createDocumentListenerGetter(
  (id) =>
    store().collection('productCatalogRetrievalProcessSettingsV1').doc(id),
  toRetrievalProcessSettingsDoc,
  () => ({
    discovery: {
      autoRun: false,
      execute: false
    },
    transform: {
      autoRun: false,
      maxParallel: 100,
      execute: false
    },
    load: {
      autoQueue: false,
      maxFilesPerTask: 10
    }
  })
);
const useSettings = () =>
  useDocumentListener(
    settingsStore(PRODUCT_CATALOG_RETRIEVAL_PROCESS_SETTINGS_ID)
  );

const StatusChip = ({ d }: { d: Doc<ProductCatalogRetrievalProcess> }) => {
  return (
    <Chip
      label={d.data.step}
      variant={d.data.running ? 'default' : 'outlined'}
      type={
        d.data.step === 'COMPLETE'
          ? 'SUCCESS'
          : d.data.step === 'ERROR'
          ? 'ERROR'
          : d.data.step === 'ABORTED'
          ? 'ABORTED'
          : d.data.step === 'INIT'
          ? 'NONE'
          : 'PENDING'
      }
    />
  );
};

const Labels = ({ labels }: { labels: string[] }) => {
  return (
    <FlexContainer spacing={0.5} wrap="wrap">
      {labels.map((l, i) => (
        <Chip
          key={i}
          size="small"
          label={l}
          variant={l === 'auto' ? 'default' : 'outlined'}
        />
      ))}
    </FlexContainer>
  );
};
type D = Doc<ProductCatalogRetrievalProcess>;
const COLUMNS: IColumn<D, string>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.id}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 100,
    flexGrow: 0
  },
  {
    key: 'step',
    head: () => 'Step',
    cell: (d) => <StatusChip d={d} />,
    align: 'left',
    width: 75
  },
  {
    key: 'catalog',
    head: () => 'Catalog',
    cell: (d) =>
      uniq(
        Object.values(d.data.downloadSources).map(
          (x) => x.source.file.catalogId
        )
      ).join(', '),
    align: 'left',
    width: 160
  },
  {
    key: 'format',
    head: () => 'Format',
    cell: (d) =>
      uniq(
        Object.values(d.data.downloadSources).map((x) => x.source.file.format)
      ).join(', '),
    align: 'left',
    width: 150
  },
  {
    key: 'labels',
    head: () => 'Labels',
    cell: (d) => <Labels labels={d.data.labels} />,
    align: 'left',
    width: 220
  },
  {
    key: 'rows',
    head: () => 'Rows',
    cell: (d) =>
      sum(Object.values(d.data.loadSources).map((x) => x.source.file.rows)) ||
      '-',
    align: 'right',
    width: 120,
    flexGrow: 0
  },
  {
    key: 'startedAt',
    head: () => 'Started At',
    cell: (d) => moment(d.data.startedAt).utc().format('YYYY-MM-DD HH:mm'),
    align: 'right',
    width: 130,
    sortable: true
  },
  {
    key: 'updatedAt',
    head: () => 'Updated At',
    cell: (d) =>
      d.data.updatedAt
        ? moment(d.data.updatedAt).utc().format('YYYY-MM-DD HH:mm')
        : '-',
    align: 'right',
    width: 130,
    sortable: true
  },
  {
    key: 'other',
    head: () => '',
    cell: (d) => (
      <LinkExternal color="primary" href={getLogLinkForProcess(d)}>
        Logs
      </LinkExternal>
    ),
    align: 'right',
    width: 40,
    sortable: false
  }
];

const rowToKey = (d: D) => d.id;

const SORTERS: ItemSorters<D> = {
  startedAt: {
    key: 'startedAt',
    items: { sort: (d) => new Date(d.data.startedAt).valueOf(), dir: 'desc' }
  },
  updatedAt: {
    key: 'updatedAt',
    items: {
      sort: (d) =>
        d.data.updatedAt ? new Date(d.data.updatedAt).valueOf() : Infinity,
      dir: 'desc'
    }
  },
  finishedAt: {
    key: 'finishedAt',
    items: {
      sort: (d) =>
        d.data.finishedAt ? new Date(d.data.finishedAt).valueOf() : Infinity,
      dir: 'desc'
    }
  }
};
const DEFAULT_SORTER = SORTERS.startedAt;

const TOPICS = {
  start: 'product-catalog-retrieval-process-start',
  transform: 'product-catalog-retrieval-process-transform',
  load: 'product-catalog-retrieval-process-load',
  abort: 'product-catalog-retrieval-process-abort'
};

const publishStart = async (args: RetrievalProcessStartArgs) => {
  await publishInstruction({
    topic: TOPICS.start,
    payload: args
  });
};

const publishTransform = async (args: RetrievalProcessTransformArgs) => {
  await publishInstruction({
    topic: TOPICS.transform,
    payload: args
  });
};

const publishLoad = async (args: RetrievalProcessLoadArgs) => {
  await publishInstruction({
    topic: TOPICS.load,
    payload: args
  });
};

const publishAbort = async (args: RetrievalProcessAbortArgs) => {
  await publishInstruction({
    topic: TOPICS.abort,
    payload: args
  });
};

const toLink = (href: string) => <LinkExternal color="primary" href={href} />;

const ProcessTabOverview = ({
  d
}: {
  d: Doc<ProductCatalogRetrievalProcess>;
}) => {
  return (
    <Section>
      <FlexContainerVertical fullWidth spacing={2}>
        <DataGrid
          items={[
            ['Firestore', toLink(toFirestoreConsoleFromDoc(d))],
            DATA_GRID_SPACER,
            ['Step', <StatusChip d={d} />],
            ['Started at', d.data.startedAt],
            ['Updated at', d.data.updatedAt || '-'],
            ['Finished at', d.data.finishedAt || '-'],
            DATA_GRID_SPACER,
            [
              'Catalog',
              uniq(
                Object.values(d.data.downloadSources).map(
                  (x) => x.source.file.catalogId
                )
              ).join(', ')
            ],

            [
              'Catalog Type',
              uniq(
                Object.values(d.data.downloadSources).map((x) => x.source.type)
              ).join(', ')
            ],
            [
              'Catalog Format',
              uniq(
                Object.values(d.data.downloadSources).map(
                  (x) => x.source.file.format
                )
              ).join(', ')
            ],
            [
              'Metadata',
              uniq(
                Object.values(d.data.downloadSources).map((x) =>
                  JSON.stringify(x.source.metadata || {})
                )
              ).join(', ')
            ],
            DATA_GRID_SPACER,
            ...Object.values(d.data.transformSources).map<
              [string, React.ReactNode]
            >((s, i) => [
              `Transform Source ${i + 1}`,
              s.source.type === 'cs'
                ? toLink(
                    getCloudStorageLink(s.source.file.path, s.source.bucket)
                  )
                : s.source.file.path
            ])
          ]}
        />
      </FlexContainerVertical>
    </Section>
  );
};

const ProcessTabTransform = ({
  d
}: {
  d: Doc<ProductCatalogRetrievalProcess>;
}) => {
  return <div>Transform</div>;
};

const LOAD_COLUMNS: IColumn<
  SourceWithStatus<ProductFeedLoadSource>,
  string,
  {
    selection: {
      selected: Set<string>;
      areAllSelected: boolean;
      selectAll: () => void;
      selectOne: (id: string, nextState: boolean) => void;
      selectNone: () => void;
    };
  }
>[] = [
  {
    key: 'selection',
    head: (p) => {
      const { areAllSelected, selectNone, selectAll, selected } = p!.selection;
      const onChange = () => (areAllSelected ? selectNone() : selectAll());
      return (
        <SelectionBox
          onChange={onChange}
          indeterminate={!areAllSelected && !!selected.size}
          value={areAllSelected}
        />
      );
    },
    alternateHead: () => 'Selection',
    cell: (d, { selection }) => {
      const isSelected = selection.selected.has(d.id);
      return (
        <SelectionBox
          onChange={() => selection.selectOne(d.id, !isSelected)}
          value={isSelected}
        />
      );
    },
    sortable: false,
    align: 'center',
    width: 50,
    flexGrow: 0
  },
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <Typography variant="caption">
        <Monospace>{d.id}</Monospace>
      </Typography>
    ),
    align: 'left',
    width: 120,
    flexGrow: 0
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => (
      <Chip
        label={d.status}
        variant={d.status === 'COMPLETE' ? 'default' : 'outlined'}
        type={
          d.status === 'COMPLETE'
            ? 'SUCCESS'
            : d.status === 'IN_PROGRESS'
            ? 'PENDING'
            : d.status === 'ERROR'
            ? 'ERROR'
            : 'NONE'
        }
      />
    ),
    align: 'center',
    width: 200,
    flexGrow: 0
  },
  {
    key: 'type',
    head: () => 'Type',
    cell: (d) => d.source.type,
    align: 'left',
    width: 120,
    flexGrow: 0
  },
  {
    key: 'rows',
    head: () => 'Rows',
    cell: (d) => d.source.file.rows,
    align: 'right',
    width: 120,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d) => {
      if (d.source.type === 'cs') {
        return (
          <FlexContainer justifyContent="flex-end" fullWidth>
            <Button
              color="primary"
              size="small"
              target="_blank"
              href={getCloudStorageLink(d.source.file.path, d.source.bucket)}
            >
              Open in Cloud Storage
            </Button>
          </FlexContainer>
        );
      }
      return null;
    },
    align: 'right',
    width: 160,
    flexGrow: 2
  }
];

const ProcessTabLoad = ({ d }: { d: Doc<ProductCatalogRetrievalProcess> }) => {
  const [showCompleted, setShowCompleted] = useState(false);
  const sel = useSet<string>();
  const sources = sortBy(Object.values(d.data.loadSources), (x) => x.id);
  const completedSouces = sources.filter((s) => s.status === 'COMPLETE');
  const visibleSources = showCompleted
    ? sources
    : sources.filter((s) => s.status !== 'COMPLETE');
  if (!sources.length) {
    return <Centered>No load sources (yet).</Centered>;
  }
  return (
    <Section>
      <CanvasBar>
        <FlexContainer>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending="Loading..."
            disabled={sel.set.size === 0}
            onClick={async () => {
              await publishLoad({
                processId: d.id,
                fileIds: [...sel.set]
              });
              sel.clear();
            }}
          >
            Load {sel.set.size} selected file(s)
          </ButtonWithPromise>

          <CheckboxSimple
            checked={showCompleted}
            onChange={setShowCompleted}
            label={`${showCompleted ? 'Hide' : 'Show'} ${
              completedSouces.length
            } completed file(s)`}
          />
        </FlexContainer>
      </CanvasBar>

      <RowsRenderer
        variant="contained"
        rows={visibleSources}
        columns={LOAD_COLUMNS}
        rowToKey={(x) => x.id}
        sorter={IDENTITY_SORTER}
        sortDirection={'asc'}
        renderHead
        otherProps={{
          selection: {
            selectOne: (id, nextState) =>
              nextState ? sel.add(id) : sel.rm(id),
            selectNone: () => sel.clear(),
            selectAll: () =>
              sel.replace(new Set(visibleSources.map((d) => d.id))),
            selected: sel.set,
            areAllSelected: sel.set.size === visibleSources.length
          }
        }}
      />
    </Section>
  );
};

const ProcessDialog = ({
  open,
  onClose,
  d
}: {
  open: boolean;
  onClose: () => void;
  d: Doc<ProductCatalogRetrievalProcess>;
}) => {
  const [selectedTab, setSelectedTab] = useState('overview');

  const catalogId = uniq(
    Object.values(d.data.downloadSources).map((x) => x.source.file.catalogId)
  ).join(', ');
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitleWithTypography variant="h6">
        <FlexContainer justifyContent="space-between">
          <div>
            <StatusChip d={d} /> {catalogId} - {d.id}
          </div>
          <div>
            <Button
              target="_blank"
              href={getLogLinkForProcess(d)}
              size="small"
              color="primary"
            >
              Open Logs
            </Button>
          </div>
          <FlexContainer>
            <ButtonWithPromise
              size="small"
              variant="contained"
              color="primary"
              disabled={d.data.step !== 'INIT'}
              pending="Starting..."
              onClick={() => publishStart({ processId: d.id })}
            >
              Start
            </ButtonWithPromise>
            <ButtonWithPromise
              size="small"
              variant="contained"
              color="primary"
              pending="Transforming..."
              onClick={() =>
                publishTransform({
                  processId: d.id,
                  fileIds: Object.keys(d.data.transformSources)
                })
              }
            >
              Transform
            </ButtonWithPromise>
            <ButtonWithPromise
              size="small"
              variant="contained"
              color="secondary"
              disabled={d.data.step === 'INIT' || d.data.step === 'DOWNLOADING'}
              pending="Starting..."
              onClick={() => {
                return updateDoc(d, () => ({
                  loadSources: {},
                  step: 'TRANSFORMING' as const,
                  finishedAt: null,
                  running: false
                }));
              }}
            >
              Revert to pre-transform
            </ButtonWithPromise>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setSelectedTab('load')}
            >
              Load...
            </Button>
            <ButtonWithPromise
              size="small"
              variant="contained"
              pending="Aborting..."
              onClick={() => publishAbort({ processId: d.id })}
            >
              Abort
            </ButtonWithPromise>
            <DialogCloseButton onClick={onClose} />
          </FlexContainer>
        </FlexContainer>
      </DialogTitleWithTypography>
      <DialogContent>
        <FlexContainerVertical fullWidth spacing={3}>
          <Tabs
            value={selectedTab}
            onChange={(_, v: string) => setSelectedTab(v)}
          >
            <Tab label="Overview" value="overview" />
            <Tab label="Transform" value="transform" />
            <Tab label="Load" value="load" />
            <Tab label="JSON" value="json" />
          </Tabs>

          {selectedTab === 'overview' && <ProcessTabOverview d={d} />}
          {selectedTab === 'transform' && <ProcessTabTransform d={d} />}
          {selectedTab === 'load' && <ProcessTabLoad d={d} />}
          {selectedTab === 'json' && <Json fullWidth data={d} />}
        </FlexContainerVertical>
      </DialogContent>
      <DialogActionsWithSlots
        left={
          <>
            <DeleteButton
              onDelete={() => {
                onClose();
                return removeDoc(d);
              }}
              variant="outlined"
              color="secondary"
            >
              Delete
            </DeleteButton>
          </>
        }
        right={
          <>
            <Button onClick={onClose}>Close</Button>
          </>
        }
      />
    </Dialog>
  );
};

const SinglePage = ({
  processId,
  onClose
}: {
  processId: string;
  onClose: () => void;
}) => {
  const [d, loading] = useProcess(processId);

  if (loading) {
    return (
      <Dialog open={true} fullScreen onClose={onClose}>
        <DialogContent>
          <Loader height="100%" />
        </DialogContent>
        <DialogActionsWithSlots
          right={<Button onClick={onClose}>Close</Button>}
        />
      </Dialog>
    );
  }

  if (d) {
    return <ProcessDialog open={true} onClose={onClose} d={d} />;
  }

  return (
    <Dialog open={true} fullScreen onClose={onClose}>
      <DialogContent>Something went wrong</DialogContent>
      <DialogActionsWithSlots
        right={<Button onClick={onClose}>Close</Button>}
      />
    </Dialog>
  );
};

const SettingsDialog = ({
  open,
  onClose,
  settings
}: {
  open: boolean;
  onClose: () => void;
  settings: Doc<ProductCatalogRetrievalProcessSettings>;
}) => {
  const [model, setModel] = useModel(settings.data);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Retrieval Process Settings</DialogTitle>
      <DialogContent>
        <FlexContainerVertical spacing={6} fullWidth>
          <FlexContainerVertical fullWidth>
            <strong>Discovery</strong>
            <CheckboxSimple
              color="primary"
              label="Run auto discovery"
              checked={model.discovery.autoRun}
              onChange={(autoRun) =>
                setModel((x) => ({
                  ...x,
                  discovery: { ...x.discovery, autoRun }
                }))
              }
            />

            <CheckboxSimple
              label="Execute auto discovery"
              checked={model.discovery.execute}
              onChange={(execute) =>
                setModel((x) => ({
                  ...x,
                  discovery: { ...x.discovery, execute }
                }))
              }
            />
          </FlexContainerVertical>
          <FlexContainerVertical fullWidth>
            <strong>Transform</strong>
            <CheckboxSimple
              color="primary"
              label="Run auto transform (every 5 minutes)"
              checked={model.transform.autoRun}
              onChange={(autoRun) =>
                setModel((x) => ({
                  ...x,
                  transform: { ...x.transform, autoRun }
                }))
              }
            />

            <CheckboxSimple
              label="Execute auto transform"
              checked={model.transform.execute}
              onChange={(execute) =>
                setModel((x) => ({
                  ...x,
                  transform: { ...x.transform, execute }
                }))
              }
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Max Parallel Transforms"
              type="number"
              value={model.transform.maxParallel}
              onChange={(ev) =>
                setModel((x) => ({
                  ...x,
                  transform: {
                    ...x.transform,
                    maxParallel: +ev.target.value
                  }
                }))
              }
            />
          </FlexContainerVertical>
          <FlexContainerVertical fullWidth>
            <strong>Loading</strong>
            <Typography variant="caption">
              Parallelization of these is handled via a task queue
            </Typography>
            <CheckboxSimple
              color="primary"
              label="Auto-queue load at the end of transforms"
              checked={model.load.autoQueue}
              onChange={(autoQueue) =>
                setModel((x) => ({
                  ...x,
                  load: { ...x.load, autoQueue }
                }))
              }
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Max files per Cloud Task"
              type="number"
              value={model.load.maxFilesPerTask}
              onChange={(ev) =>
                setModel((x) => ({
                  ...x,
                  load: {
                    ...x.load,
                    maxFilesPerTask: +ev.target.value
                  }
                }))
              }
            />
          </FlexContainerVertical>
        </FlexContainerVertical>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending="Saving..."
          disabled={isEqual(model, settings.data)}
          onClick={() =>
            setDoc({
              ...settings,
              data: model
            })
          }
        >
          Save
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const ListPage = ({
  setSelectedProcessId
}: {
  setSelectedProcessId: (processId: string) => void;
}) => {
  const [ds] = useProcesses();
  const [[sorter, dir], setSort] = useSortQueryParam('sort', SORTERS);

  const [settings] = useSettings();
  const settingsD = useDialogState();

  const [q, setQ] = useStringQueryParam('q');
  const rows = useMemo(() => {
    if (!ds) {
      return null;
    }
    const re = toSearchRegexp(q);
    return re ? ds.filter((d) => !!d.data.labels.find((l) => l.match(re))) : ds;
  }, [ds, q]);

  return (
    <Page width="FULL">
      <>
        {!rows && <Loader height={500} />}
        {rows && (
          <>
            <CanvasBar>
              <SearchInput
                value={q}
                onChange={setQ}
                placeholder="Search by labels"
                autoFocus={true}
                width={300}
              />
              <Button onClick={settingsD.openDialog}>Settings...</Button>
            </CanvasBar>
            <RowsRenderer
              variant="contained"
              rows={rows}
              columns={COLUMNS}
              rowToKey={rowToKey}
              sorter={sorter || DEFAULT_SORTER}
              sortDirection={sorter ? dir : DEFAULT_SORTER.items.dir}
              onHeadClick={(c, d) =>
                setSort([SORTERS[c.key] || DEFAULT_SORTER, d])
              }
              chunkSize={100}
              rootMargin="400px"
              rowHeight={ROW_HEIGHTS.dense}
              renderHead
              otherProps={undefined}
              onRowClick={(d) => setSelectedProcessId(d.id)}
            />
          </>
        )}
      </>

      {settings && (
        <SettingsDialog
          open={settingsD.dialogOpen}
          onClose={settingsD.closeDialog}
          settings={settings}
        />
      )}
    </Page>
  );
};

export const PageProductCatalogRetrievalProcesses = () => {
  const [selectedProcessId, setSelectedProcessId] = useStringQueryParam(
    'process',
    ''
  );

  if (selectedProcessId) {
    return (
      <SinglePage
        processId={selectedProcessId}
        onClose={() => setSelectedProcessId('')}
      />
    );
  }

  return <ListPage setSelectedProcessId={setSelectedProcessId} />;
};
