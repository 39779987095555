import { chunk, every, last } from 'lodash';
import moment from 'moment-timezone';
import { DAY_FORMAT, Timeframe } from '../../domainTypes/analytics';
import { IDenormalizationParams } from '../../domainTypes/denormalization';
import { ISpace } from '../../domainTypes/space';
import { allTime_ } from '../../services/analytics';
import { callFirebaseFunction } from '../../services/firebaseFunctions';
import { CF } from '../../versions';

const MAX_DAYS_PER_DENORMALIZATION = 30;
const VOID = () => {};

type QueueItem<T> = {
  fn: () => Promise<T>;
  started: boolean;
  done: boolean;
};

export const processParallelCapped = async <T>(
  fns: (() => Promise<T>)[],
  capSize: number
): Promise<T[]> => {
  if (!fns.length) {
    return [];
  }
  return new Promise((resolve, reject) => {
    const results: T[] = [];
    const errors: any[] = [];
    const queue: QueueItem<T>[] = fns.map((fn) => ({
      fn,
      started: false,
      done: false
    }));
    const processNext = () => {
      const nextItem = queue.find((item) => !item.started);
      if (nextItem) {
        processItem(nextItem);
      } else {
        if (every(queue, (item) => item.done)) {
          if (errors.length) {
            reject(errors);
          } else {
            resolve(results);
          }
        }
      }
    };
    const processItem = (item: QueueItem<T>) => {
      item.started = true;
      item.fn().then(
        (res) => {
          results[queue.indexOf(item)] = res;
          item.done = true;
          processNext();
        },
        (err) => {
          errors.push(err);
          item.done = true;
          processNext();
        }
      );
    };

    queue.slice(0, capSize).forEach(processItem);
  });
};

const toMoment = (d: string) => moment(d, DAY_FORMAT);

const getRange = (start: string, end: string): string[] => {
  const s = toMoment(start);
  const e = toMoment(end);
  const result: string[] = [];
  while (s.isSameOrBefore(e)) {
    result.push(s.format('YYYY-MM-DD'));
    s.add(1, 'day');
  }
  return result;
};

const chunkTimeframe = (
  timeframe: Timeframe,
  chunkSize: number
): Timeframe[] => {
  const range = getRange(timeframe.start, timeframe.end);
  const chunks = chunk(range, chunkSize);
  return chunk(range, 30).map((days) => {
    const start = days[0];
    const end = days[days.length - 1];
    // remember that the end is exlusive. this means that chunks in between need
    // to add a day
    const calibratedEnd =
      last(chunks) === days
        ? end
        : toMoment(end).add(1, 'd').format(DAY_FORMAT);

    return {
      start,
      end: calibratedEnd,
      tz: timeframe.tz
    };
  });
};

const toDenormalizationParams = (space: ISpace): IDenormalizationParams[] => {
  return chunkTimeframe(
    allTime_(space, space.config.tz || 'UTC'),
    MAX_DAYS_PER_DENORMALIZATION
  ).map((timeframe) => ({ spaceId: space.id, timeframe }));
};

export const denornmalizeAnalytics = async (
  params: IDenormalizationParams
): Promise<void> => {
  return callFirebaseFunction(CF.denormalization.denormalizeSpace, params).then(
    VOID
  );
};

export const denormalizeAnalyticsForSpace = (space: ISpace): Promise<void> => {
  const params = toDenormalizationParams(space);
  return Promise.all(params.map(denornmalizeAnalytics)).then(VOID);
};

export const denormalizeAnalyticsForSpaceInTimeframe = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return denornmalizeAnalytics({
    spaceId,
    timeframe,
    goToCloudStorageIfNeedBe: true
  });
};
