import { compact, keyBy } from 'lodash';
import { ISOTimeRange } from '../../domainTypes/analytics_v2';
import {
  IProductCatalogRowV1,
  ProductCatalogFindSimilarProductsReponseV1,
  ProductCatalogHistoryInterval,
  ProductCatalogHistoryResponse
} from '../../domainTypes/productCatalog';
import { useDeepEqual } from '../../hooks/useDeepEqual';
import { usePromise } from '../../hooks/usePromise';
import { callFirebaseFunction } from '../../services/firebaseFunctions';

export const productCatalogFindSimilarProducts = (
  urls: string[],
  opts: {
    alwaysIncludeSimilarMatches?: boolean;
  } = {}
): Promise<{
  time: number;
  data: ProductCatalogFindSimilarProductsReponseV1[];
}> => {
  return callFirebaseFunction<{
    time: number;
    data: ProductCatalogFindSimilarProductsReponseV1[];
  }>('productCatalog-findSimilarProducts', {
    urls,
    opts: {
      alwaysIncludeSimilarMatches: opts.alwaysIncludeSimilarMatches ?? true
    }
  });
};

export const _productCatalogGetItems = async (
  uids: string[]
): Promise<IProductCatalogRowV1[]> => {
  const res = await callFirebaseFunction<{
    items: IProductCatalogRowV1[];
  }>('productCatalog-getProductCatalogItems', {
    uids
  });
  return res.items;
};

let PRODUCT_CATALOG_ITEMS_CACHE: {
  [uid: string]: IProductCatalogRowV1 | null;
} = {};

export type ProductCatalogGetItemsOptions = {
  noCache?: boolean;
};

export const productCatalogGetItemsCacheFlush = () =>
  (PRODUCT_CATALOG_ITEMS_CACHE = {});

export const productCatalogGetItems = async (
  uids: string[],
  opts: ProductCatalogGetItemsOptions = {}
): Promise<IProductCatalogRowV1[]> => {
  const remainingItems = opts.noCache
    ? uids
    : uids.filter((uid) => PRODUCT_CATALOG_ITEMS_CACHE[uid] === undefined); // null is allowed - we just don't have this item
  if (remainingItems.length) {
    const newItems = await _productCatalogGetItems(remainingItems);
    const newItemsById = keyBy(newItems, (item) => item.uid);
    remainingItems.forEach((uid) => {
      const newItem = newItemsById[uid] || null;
      PRODUCT_CATALOG_ITEMS_CACHE[uid] = newItem;
    });
  }
  return compact(uids.map((uid) => PRODUCT_CATALOG_ITEMS_CACHE[uid]));
};

export const productCatalogGetItem = async (
  uid: string,
  opts: ProductCatalogGetItemsOptions = {}
): Promise<IProductCatalogRowV1 | null> => {
  const items = await productCatalogGetItems([uid], opts);
  return items[0] || null;
};

export const useProductCatalogItem = (uid: string) => {
  return usePromise(() => productCatalogGetItem(uid), [uid]);
};

let PRODUCT_CATALOG_GET_HISTORY_CACHE: {
  [key: string]: Promise<ProductCatalogHistoryResponse>;
} = {};

export const productCatalogGetHistoryCacheFlush = () =>
  (PRODUCT_CATALOG_GET_HISTORY_CACHE = {});

const _productCatalogGetHistory = async (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  const res = await callFirebaseFunction<ProductCatalogHistoryResponse>(
    'productCatalog-getProductCatalogHistory',
    {
      pc_uid: uid,
      range,
      interval
    }
  );
  return res;
};

export const productCatalogGetHistory = async (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  const key = [
    uid,
    range.start,
    range.end,
    interval.tz,
    interval.unit,
    interval.value
  ]
    .map((x) => x.toString())
    .join('---');
  return (PRODUCT_CATALOG_GET_HISTORY_CACHE[key] =
    PRODUCT_CATALOG_GET_HISTORY_CACHE[key] ||
    _productCatalogGetHistory(uid, range, interval));
};

export const useProductCatalogHistory = (
  uid: string,
  range: ISOTimeRange,
  interval: ProductCatalogHistoryInterval
) => {
  return usePromise(() => productCatalogGetHistory(uid, range, interval), [
    uid,
    useDeepEqual(range),
    useDeepEqual(interval)
  ]);
};
