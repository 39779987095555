import { TextField, Typography } from '@material-ui/core';
import { truncate } from 'lodash';
import React, { useState } from 'react';
import { Form } from '../../../../../components/Form';
import { FormField } from '../../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../../components/Form/FormSubmitButton';
import { LinkExternal } from '../../../../../components/LinkExternal';
import { ProductCatalogFindSimilarProductsReponseV1 } from '../../../../../domainTypes/productCatalog';
import { ProductCatalogRowCard } from '../../../../../features/ProductCatalog/components/ProductCatalogRow';
import { productCatalogFindSimilarProducts } from '../../../../../features/ProductCatalog/service';
import { CanvasBar } from '../../../../../layout/Canvas';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { toCloudFunctionGen2LogLink } from '../../../../../services/logging';
import { Json } from '../../../../components/Json';

const splitMultilineInput = (txt: string) =>
  txt
    .trim()
    .split('\n')
    .map((x) => x.trim());

const Response = ({ d }: { d: ProductCatalogFindSimilarProductsReponseV1 }) => {
  return (
    <FlexContainerVertical fullWidth spacing={3}>
      <FlexContainerVertical>
        <strong>
          Product -{' '}
          <Typography variant="caption">
            {truncate(d.url, { length: 75 })}
          </Typography>
        </strong>
        {d.p ? <ProductCatalogRowCard d={d.p} /> : '-'}
      </FlexContainerVertical>

      <FlexContainerVertical>
        <strong>Direct Matches</strong>
        {d.directMatches.map((x) => (
          <ProductCatalogRowCard key={x.uid} d={x} />
        ))}
      </FlexContainerVertical>
      <FlexContainerVertical>
        <strong>Similar Matches</strong>
        {d.similarMatches.map((x) => (
          <ProductCatalogRowCard key={x.uid} d={x} />
        ))}
      </FlexContainerVertical>
    </FlexContainerVertical>
  );
};

export const PageProductCatalogFindSimilarProducts = () => {
  const [res, setRes] = useState<{
    time: number;
    data: ProductCatalogFindSimilarProductsReponseV1[];
  } | null>(null);
  return (
    <Page width="L">
      <Section>
        <Form
          initialValues={{ urls: '' }}
          onSubmit={(values) =>
            productCatalogFindSimilarProducts(
              splitMultilineInput(values.urls),
              { alwaysIncludeSimilarMatches: true }
            ).then((res) => {
              console.log('RES', res);
              setRes(res);
            })
          }
        >
          {({ submitting, values }) => (
            <FlexContainer fullWidth>
              <FormField<string> name="urls">
                {({ input }) => (
                  <TextField
                    variant="outlined"
                    label="URLs (one URL per line)"
                    multiline
                    {...input}
                    fullWidth
                    autoFocus
                  />
                )}
              </FormField>
              <FormSubmitButton
                submitComponent="Searching..."
                submitting={submitting}
                variant="contained"
                disabled={!values.urls || values.urls.length < 3}
                color="primary"
                size="large"
              >
                Search
              </FormSubmitButton>
            </FlexContainer>
          )}
        </Form>
        <LinkExternal
          color="primary"
          href={toCloudFunctionGen2LogLink(
            'productCatalog-findSimilarProducts'
          )}
        >
          Open Logs
        </LinkExternal>
      </Section>
      <Section>
        {res && (
          <FlexContainerVertical spacing={5} fullWidth>
            {res.data.map((r, i) => {
              return (
                <React.Fragment key={i}>
                  <Response d={r} />
                </React.Fragment>
              );
            })}
          </FlexContainerVertical>
        )}
      </Section>
      <Section>
        <CanvasBar>Results</CanvasBar>
        {res && <Json data={res} />}
      </Section>
    </Page>
  );
};
