import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Form } from '../../../../../components/Form';
import { FormField } from '../../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../../components/Form/FormSubmitButton';
import { LinkExternal } from '../../../../../components/LinkExternal';
import { CanvasBar } from '../../../../../layout/Canvas';
import { FlexContainer } from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { Json } from '../../../../components/Json';

const FN = 'productCatalog-productSearchViaEmbedding';

export const PageProductCatalogProductSearchViaEmbedding = () => {
  const [rows, setRows] = useState<any[]>([]);
  return (
    <Page width="L">
      <Section>
        <Form
          initialValues={{ input: '' }}
          onSubmit={(values) =>
            callFirebaseFunction(FN, {
              inputs: values.input
            }).then((rows) => {
              console.log('ROWS', rows);
              setRows(rows as any[]);
            })
          }
        >
          {({ submitting, values }) => (
            <FlexContainer fullWidth>
              <FormField<string> name="input">
                {({ input }) => (
                  <TextField
                    variant="outlined"
                    label="Input"
                    {...input}
                    fullWidth
                    autoFocus
                  />
                )}
              </FormField>
              <FormSubmitButton
                submitComponent="Searching..."
                submitting={submitting}
                variant="contained"
                disabled={!values.input || values.input.length < 3}
                color="primary"
                size="large"
              >
                Search
              </FormSubmitButton>
            </FlexContainer>
          )}
        </Form>
        <LinkExternal color="primary" href={'TODO'} />
      </Section>
      <Section>
        <CanvasBar>Results</CanvasBar>
        <Json data={rows} />
      </Section>
    </Page>
  );
};
