import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Form } from '../../../../../components/Form';
import { FormField } from '../../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../../components/Form/FormSubmitButton';
import { CanvasBar } from '../../../../../layout/Canvas';
import { FlexContainer } from '../../../../../layout/Flex';
import { Page } from '../../../../../layout/Page';
import { Section } from '../../../../../layout/Section';
import { callFirebaseFunction } from '../../../../../services/firebaseFunctions';
import { Json } from '../../../../components/Json';

export const PageProductCatalogProductMatchByUrl = () => {
  const [rows, setRows] = useState<any[]>([]);
  return (
    <Page width="L">
      <Section>
        <Form
          initialValues={{ url: '' }}
          onSubmit={(values) =>
            callFirebaseFunction(
              'productCatalog-productMatchByUrl',
              values
            ).then((rows) => {
              console.log('ROWS', rows);
              setRows(rows as any[]);
            })
          }
        >
          {({ submitting, values }) => (
            <FlexContainer fullWidth>
              <FormField<string> name="url">
                {({ input }) => (
                  <TextField
                    variant="outlined"
                    label="URL"
                    {...input}
                    fullWidth
                    autoFocus
                  />
                )}
              </FormField>
              <FormSubmitButton
                submitComponent="Matching..."
                submitting={submitting}
                variant="contained"
                disabled={!values.url || values.url.length < 3}
                color="primary"
                size="large"
              >
                Match
              </FormSubmitButton>
            </FlexContainer>
          )}
        </Form>
      </Section>
      <Section>
        <CanvasBar>Results</CanvasBar>
        <Json data={rows} />
      </Section>
    </Page>
  );
};
