import { Card, CardContent, Typography } from '@material-ui/core';
import { truncate } from 'lodash';
import React, { useMemo } from 'react';
import { DataGrid } from '../../../../admin/components/DataGrid';
import { LinkExternalWithEllipsis } from '../../../../components/LinkExternal';
import { Currency } from '../../../../components/Number';
import { COLORS } from '../../../../domainTypes/colors';
import {
  IProductCatalogRowV1,
  ProductCatalogAvailability
} from '../../../../domainTypes/productCatalog';
import { styled } from '../../../../emotion';
import { FlexContainer, FlexContainerVertical } from '../../../../layout/Flex';

const Swatch = styled('div')<{ size: number; color: string }>((p) => ({
  height: p.size,
  width: p.size,
  borderRadius: '25%',
  backgroundColor: p.color
}));

const Availability = ({ status }: { status: ProductCatalogAvailability }) => {
  const color = useMemo(() => {
    switch (status) {
      case ProductCatalogAvailability.in_stock:
        return COLORS.green.green5;
      case ProductCatalogAvailability.backorder:
      case ProductCatalogAvailability.preorder:
        return COLORS.gold.gold5;
      case ProductCatalogAvailability.out_of_stock:
        return COLORS.red.red5;
      default:
        return '#AAA';
    }
  }, [status]);
  return (
    <FlexContainer>
      <Swatch size={16} color={color} />
      <div>{status}</div>
    </FlexContainer>
  );
};

const ProductImage = styled('img')((p) => ({
  objectFit: 'contain',
  objectPosition: 'top',
  width: '100%',
  height: '100%'
}));

const InnerGrid = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  gridColumnGap: p.theme.spacing(2),
  alignItems: 'flex-start'
}));

const replaceLeadingZeros = (x: string) => x.replace(/^0+/, '');

const DataGridOuter = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: p.theme.spacing(5),
  gridRowGap: p.theme.spacing(2)
}));

export const ProductCatalogRowCard = ({ d }: { d: IProductCatalogRowV1 }) => {
  return (
    <Card>
      <CardContent>
        <InnerGrid>
          <ProductImage src={d.image_link} />
          <FlexContainerVertical fullWidth>
            <FlexContainerVertical fullWidth spacing={0}>
              <Typography variant="caption">
                <strong>{d.title}</strong>
              </Typography>
              <Typography variant="caption">
                <LinkExternalWithEllipsis href={d.link} color="primary">
                  {truncate(d.link, { length: 100 })}
                </LinkExternalWithEllipsis>
              </Typography>
            </FlexContainerVertical>
            <DataGridOuter>
              <DataGrid
                items={[
                  ['Brand', d.brand],
                  ['UID', d.uid]
                ]}
              />
              <DataGrid
                items={[
                  [
                    'Identifier',
                    replaceLeadingZeros(d.gtin || d.ean || d.upc || '-')
                  ],
                  [
                    'Price',
                    [
                      <FlexContainer spacing={0.5}>
                        {d.price_usd !== d.orig_price_usd && (
                          <div
                            style={{
                              textDecoration: 'line-through',
                              color: COLORS.red.red5
                            }}
                          >
                            <Currency cents={d.orig_price_usd} currency="USD" />
                          </div>
                        )}

                        <Currency cents={d.price_usd} currency="USD" />
                      </FlexContainer>
                    ]
                  ]
                ]}
              />
              <DataGrid
                items={[
                  ['GTIN', d.gtin || '-'],
                  ['EAN', d.ean || '-'],
                  ['UPC', d.upc || '-']
                ]}
              />
              <DataGrid
                items={[
                  ['MPN', d.mpn || '-'],
                  ['ASIN', d.asin || '-'],
                  ['ISBN', d.isbn || '-']
                ]}
              />
            </DataGridOuter>
            <Availability status={d.availability} />
          </FlexContainerVertical>
        </InnerGrid>
      </CardContent>
    </Card>
  );
};
